<template>
  <div>
    <div class="page-name">
      <div class="page-title">User & Role Manager</div>
    </div>

    <div class="page-container">
      <form id="roleForm" @submit.prevent="onSubmit">
        <b-card class="w-100">
          <div class="flex-row align-center justify-between">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Setting Role Account</h5>
            </div>
          </div>

          <hr />

          <b-form-group style="font-weight: 600" label="Role Name">
            <b-form-input
              type="text"
              placeholder="Role Name"
              v-model="form.name"
            ></b-form-input>
          </b-form-group>

          <b-form-group style="font-weight: 600" label="Role Description">
            <b-form-input
              type="text"
              placeholder="Role Description"
              v-model="form.description"
            ></b-form-input>
          </b-form-group>

          <hr />

          <div class="flex-row align-center justify-between">
            <div class="flex-col" style="flex: 1">
              <h5 class="page-title">Setting Permission For Menu Access</h5>
            </div>
          </div>

          <p-check
            v-on:change="(e) => this.onCheckAll(e)"
            v-model="isCheckAll"
            name="check"
            class="p-icon p-smooth p-bigger mt-2vh"
            color="primary"
            style="margin-right: 20px"
          >
            <i slot="extra" class="icon fa fa-check"></i> Access All
          </p-check>

          <hr />

          <b-row>
            <b-col lg="3">
              <p-check
                v-model="form.roles.cover_page"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
                :sync="true"
              >
                <i slot="extra" class="icon fa fa-check"></i> Cover Page
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.home_page"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Home Page
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.project"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Project
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.register"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Register
              </p-check>
            </b-col>
          </b-row>

          <b-row class="row-space-top">
            <b-col lg="3">
              <p-check
                v-model="form.roles.seo"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> SEO
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.advance_search"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Advance Search
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.news_article"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> News / Article
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.grand_u_fam"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Grand Unity Family
              </p-check>
            </b-col>
          </b-row>

          <b-row class="row-space-top">
            <b-col lg="3">
              <p-check
                v-model="form.roles.promotion"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Promotion
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.about"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> About
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.contact"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Contact
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.job"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Job
              </p-check>
            </b-col>
          </b-row>

          <b-row class="row-space-top">
            <b-col lg="3">
              <p-check
                v-model="form.roles.mobile_app"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Mobile App
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.foreign_buyer_guide"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Foreign Buyer
                Guide
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.calculator"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Calculator
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.sitemap"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Sitemap
              </p-check>
            </b-col>
          </b-row>

          <b-row class="row-space-top">
            <b-col lg="3">
              <p-check
                v-model="form.roles.terms_conditions"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Term & Condition
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.privacy"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> Privacy
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.file_manager"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> File Manager Tools
              </p-check>
            </b-col>

            <b-col lg="3">
              <p-check
                v-model="form.roles.user_role_manager"
                v-on:change="this.onValidateCheck"
                name="check"
                class="p-icon p-smooth p-bigger"
                color="primary"
                style="margin-right: 20px"
              >
                <i slot="extra" class="icon fa fa-check"></i> User & Role
                Manager
              </p-check>
            </b-col>
          </b-row>

          <div class="footer-btn-zone">
            <b-btn size="sm" variant="primary" class="mx-1" type="submit"
              >Save</b-btn
            >
            <b-btn
              size="sm"
              variant="secondary"
              class="mx-1"
              type="button"
              @click="this.goBack"
              >Cancel</b-btn
            >
          </div>
        </b-card>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  data: () => ({
    form: {
      name: '',
      description: '',
      active: 0,
      roles: {
        cover_page: false,
        home_page: false,
        project: false,
        register: false,
        seo: false,
        advance_search: false,
        news_article: false,
        grand_u_fam: false,
        promotion: false,
        about: false,
        contact: false,
        job: false,
        mobile_app: false,
        foreign_buyer_guide: false,
        calculator: false,
        sitemap: false,
        terms_conditions: false,
        privacy: false,
        file_manager: false,
        user_role_manager: false
      }
    },
    isCheckAll: false
  }),
  methods: {
    ...mapActions({
      createData: ActionFetch.ROLE.CREATE,
      getInfo: ActionFetch.ROLE.DETAIL,
      updateData: ActionFetch.ROLE.UPDATE
    }),
    goBack () {
      this.$router.push(`/role/role`)
    },
    onCheckAll ($value) {
      const keyList = Object.keys(this.form.roles)

      for (let key of keyList) {
        this.form.roles[key] = $value
      }
    },
    onValidateCheck () {
      const keyList = Object.keys(this.form.roles)
      let resultAllCheck = true

      for (let key of keyList) {
        if (!this.form.roles[key]) {
          resultAllCheck = false
        }
      }

      this.isCheckAll = resultAllCheck
    },
    onSubmit ($e) {
      $e.preventDefault()

      const keyList = Object.keys(this.form.roles)
      let hasRow = false

      for (let key of keyList) {
        if (this.form.roles[key]) {
          hasRow = true
        }
      }

      if (!this.form.name) {
        this.$bvToast.toast(`Please input role name`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else if (!hasRow) {
        this.$bvToast.toast(`Please select at least one permission`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      } else {
        if (!this.form.id) {
          this.createData(this.form).then(($res) => {
            if ($res.success) {
              this.$bvToast.toast(`Create Role successful`, {
                title: 'Role created',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'success',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })

              setTimeout(() => {
                this.$router.push(`/role/role`)
              }, 1000)
            }
          })
        } else {
          this.updateData(this.form).then(($res) => {
            if ($res.success) {
              this.$bvToast.toast(`Update Role successful`, {
                title: 'Role updated',
                autoHideDelay: 2000,
                appendToast: true,
                variant: 'success',
                toaster: 'b-toaster-bottom-right',
                solid: true
              })

              setTimeout(() => {
                this.$router.push(`/role/role`)
              }, 1000)
            }
          })
        }
      }
    },
    fetchInfo () {
      const params = {
        id: +this.$route.params.id
      }

      if (+this.$route.params.id) {
        this.getInfo(params).then(($res) => {
          if ($res.success) {
            this.form = { ...$res.data }
            this.onValidateCheck()

            delete this.form.createdAt
            delete this.form.updatedAt
          }
        })
      }
    }
  },
  created () {
    this.fetchInfo()
  }
}
</script>
<style>
.mt-2vh {
  margin-top: 4vh;
}

.row-space-top {
  margin-top: 3vh;
}

.footer-btn-zone {
  margin-top: 5vh;
}
</style>
